import dayjs from "dayjs";
export const timeTransform = time => {
  const now = dayjs();
  const date = dayjs(time);
  const hourDiff = now.diff(date, "hour");
  const minuteDiff = now.diff(date, "minute");
  const monthDiff = now.diff(date, "month");
  if (minuteDiff < 1) {
    return "刚刚";
  }
  if (hourDiff < 1) {
    return minuteDiff + "分钟前";
  }
  if (hourDiff < 24) {
    return hourDiff + "小时前";
  }
  if (monthDiff < 2) {
    const dayDiff = now.diff(date, "day");
    return dayDiff + "天前";
  }
  if (monthDiff < 12) {
    const monthDiff = now.diff(date, "month");
    return monthDiff + "月前";
  }
  const yearDiff = now.diff(date, "year");
  return yearDiff + "年前";
};
export const timeFormat = function() {
  const now = dayjs();
  console.log(now);
  const year = dayjs().year();
  let month = dayjs().month() + 1;
  let day = dayjs().date();
  let hour = dayjs().hour();
  let minute = dayjs().minute();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  console.log(year + "." + month + "." + day + " " + hour + ":" + minute);
  return year + "." + month + "." + day + " " + hour + ":" + minute;
};
export const getVideoDuration = function(url) {
  return new Promise(resolve => {
    let video = document.createElement("video");
    video.preload = "metadata";
    video.src = url;
    video.onloadedmetadata = () => {
      resolve(parseInt((video.duration * 1000).toString(), 10));
      video = null;
    };
  });
};
// 获取文件的后缀
export const fileType = filename => {
  if (!filename) return "";
  const suffixIndex = filename.lastIndexOf(".");
  if (suffixIndex === -1) return "";
  const suffix = filename.substring(suffixIndex + 1).toLowerCase();
  return suffix;
};
