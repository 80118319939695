<template>
  <div class="poster-box" v-show="visible" @click="writeMessageFun($event)">
    <div class="poster-img" ref="msk">
      <img :src="posterImg" alt="" />
    </div>
    <vue-canvas-poster
      :painting="painting"
      @success="canvasSuccess"
      @fail="canvasFail"
    ></vue-canvas-poster>
  </div>
</template>
<script>
import { timeFormat } from "@/utils/utils.js";
export default {
  data() {
    return {
      // 分享海报的数据
      painting: {
        width: "360px",
        height: "620px",
        background: require("../../../src/assets/images/share-bg.png"),
        views: [
          {
            type: "image",
            url: "",
            // url: require("../../../src/assets/images/house.jpeg"),
            css: {
              top: "20px",
              left: "20px",
              width: "50px",
              height: "50px",
              borderRadius: "30px",
              mode: "aspectFill"
            }
          },
          {
            type: "text",
            text: "",
            css: {
              top: "25px",
              left: "75px",
              width: "280px",
              color: "#000000",
              maxLines: 1,
              textAlign: "left",
              fontFamily: "PingFang SC",
              fontWeight: "500",
              fontSize: "18px"
            }
          },
          {
            type: "text",
            text: "",
            css: {
              top: "50px",
              left: "75px",
              width: "280px",
              color: "#7D7D7D",
              fontFamily: "PingFang SC",
              fontWeight: "300",
              maxLines: 1,
              textAlign: "left",
              fontSize: "12px"
            }
          },
          {
            type: "image",
            url: "",
            // url:
            //   "http://gjdl960.oss-cn-beijing.aliyuncs.com/202202/85f814918e82f0fa1d3d67e6f02f103a.jpeg",
            css: {
              top: "90px",
              left: "0px",
              width: "360px",
              height: "310px",
              borderRadius: "0px",
              mode: "aspectFill"
            }
          },
          {
            type: "text",
            text: "作品名称",
            css: {
              top: "420px",
              left: "20px",
              color: "#000000",
              maxLines: 1,
              fontFamily: "PingFang SC",
              fontWeight: "500",
              textAlign: "center",
              fontSize: "16px",
              borderColor: "red",
              borderLeft: "8px"
            }
          },
          {
            type: "text",
            text: "简介",
            css: {
              top: "446px",
              left: "20px",
              color: "#000000",
              maxLines: 1,
              textAlign: "center",
              fontFamily: "PingFang SC",
              fontWeight: "500",
              fontSize: "12px",
              borderColor: "red",
              borderLeft: "8px"
            }
          },
          {
            type: "rect",
            css: {
              top: "448px",
              left: "51px",
              width: "3px",
              height: "12px",
              color: "red",
              borderRadius: "6px",
              maxLines: 1
            }
          },
          {
            type: "text",
            text: "作品简介",
            css: {
              top: "446px",
              left: "60px",
              color: "#000000",
              width: "200px",
              maxLines: 3,
              fontFamily: "PingFang SC",
              fontWeight: "300",
              textAlign: "left",
              fontSize: "12px",
              lineHeight: "18px",
              borderColor: "red",
              borderLeft: "8px"
            }
          },
          {
            type: "text",
            text: "2022 03 18 11:30",
            css: {
              bottom: "80px",
              left: "20px",
              color: "#7D7D7D",
              width: "200px",
              fontFamily: "PingFang SC",
              fontWeight: "300",
              textAlign: "left",
              fontSize: "10px",
              lineHeight: "16px",
              borderLeft: "8px"
            }
          },
          {
            type: "qrcode",
            content: "https://www.baidu.com/",
            css: {
              bottom: "86px",
              left: "299px",
              color: "#000",
              background: "#fff",
              width: "41px",
              height: "41px",
              borderWidth: "0px",
              borderColor: "#fff"
            }
          }
        ]
      },
      posterImg: "", // 生成的海报图片路径
      // 弹框状态
      visible: false
    };
  },
  methods: {
    /**
     * 操作模态框显示
     * info 作品信息
     */
    show(posterInfo) {
      console.log(timeFormat());
      console.log(123456789123, this.painting, posterInfo);
      const shareTime = timeFormat();
      console.log(shareTime);
      this.painting.views[0].url = posterInfo.headimage;
      this.painting.views[1].text = posterInfo.nickname;
      this.painting.views[2].text = posterInfo.user_description;
      this.painting.views[3].url = posterInfo.url;
      this.painting.views[4].text = posterInfo.title;
      this.painting.views[7].text = posterInfo.description;
      this.painting.views[8].text = shareTime;
      this.painting.views[9].content = posterInfo.content;
      this.visible = true;
    },
    writeMessageFun(ev) {
      if (!this.$refs.msk.contains(ev.target)) {
        this.visible = false;
      }
    },

    // 成功生成海报
    canvasSuccess(src) {
      this.posterImg = src; // 生成是base64格式的图片
    },
    // 修改内容
    handleChangeImg() {
      console.log(99999999);
    },

    // 无法生成海报
    canvasFail(err) {
      alert(err);
    }
  }
};
</script>
<style lang="scss" scoped>
.poster-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 100000;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.65);
  .poster-img {
    position: relative;
    width: 20vw;
    cursor: pointer;
    z-index: 1;
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
